import React from 'react';
import Typed from 'react-typed';

const Intro = () => {
    return (
        <div id='Intro' className='text-white bg-[#1D2634]'>
          <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center px-4'>
            <h1 className='md:text-7xl sm:text-6xl text-5xl text-[#57757E] font-bold md:py-4'>
            I'm OLIVER.
            </h1>
            <div className='flex justify-center items-center'>
              <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>
                I like to talk about
              </p>
              <Typed
          className='md:text-5xl sm:text-4xl text-xl text-[#DA031E] font-bold md:pl-4 pl-2'
            strings={['GROWTH', 'DATA', 'MAAS']}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
            </div>
            <p className='md:text-xl text-xl font-regular text-white'>Driving Marketing Transformation for global brands and local SMB's.<br /> Fractional Head of Marketing | Marketing Consultant | Change Agent | Data Driven Growth Hacker | Front-End Development enthusiast! 🚀</p>
            <button className='bg-[#57757E] w-[150px] rounded-md font-medium my-6 mx-auto py-3 text-white hover:scale-105 duration-500 scroll-smooth'><a href='#Business'>Learn More</a></button>
          </div>
        </div>
      );
    };
    
    export default Intro;