import React from 'react';

const Experience = () => {
    return (
      <div id='Experience' className='w-full bg-white py-16 px-4'>
        <div className='max-w-[1240px] mx-auto grid lg:grid-cols-2'>
          <div className='lg:col-span-2 my-4'>
            <h2 className='md:text-4xl sm:text-3xl text-3xl font-bold py-2 mb-4 text-[#1D2634] text-center'>
              MY EXPERIENCE
            </h2>
            <p className='md:text-xl text-xl font-light'>I have led multi-disciplinary teams of digital experts and overseen the development and delivery of a variety of global digital growth initiatives. I am highly organised, proactive, a change agent with excellent stakeholder management skills.<br /><br />
            <b>My experience include</b>:<br /><br />
            <ul class='list-disc px-6'>
            <li>Led Integrated Marketing for a $1B global business unit (<b>Personal Care</b>)</li>
            <li>Led a multi brand digital transformation for a $800M global business (<b>Food Manufacturing</b>)</li>
            <li>Drove Data Driven Marketing strategies to win in customer intimacy through digital excellence with +300% YoY growth</li>
            <li>IT business owner for SFDC, Pardot, 3 CMS (Drupal, Sitecore) platforms with +12K pages with +1M yearly visits in 15 languages, +2K SKU’s, +7K recipes and an order portal in 3 languages</li>
            <li>Developed content strategies for multiple global brands (<b>IT, Food Manufacturing, Personal Care</b>)</li>
            <li>Developed and implemented global and regional customer centric B2C/B2B digital marketing and ABM strategies (<b>Consumer Electronics, Food Products Manufacturing, Health and Advertising</b>) with an average of 5.4 ROAS</li>
            <li>Global and regional B2C/B2B campaign development and management</li>
            <li>Managed $2M+ annual global and regional marketing budget</li>
            <li>Developed B2C/B2B marketing automation campaigns (Salesforce/Pardot) for 500K+ audiences in 7 segments with +50% YoY growth</li>
            <li>Led B2C/B2B IT eCommerce (<b>Consumer Electronics</b>)</li>
            <li>Acquisition and pipeline management of 500+ EMEA SMB advertiser accounts (<b>SaaS</b>)</li>
            <li>Portfolio management of 400+ EMEA High Value Agency and 70+ SMB DACH advertiser accounts</li>
            <li>6+ years international project management (<b>Entertainment, Hospitality</b>)</li>
            <li>10+ years leadership and people development experience up to 30 direct reports</li>
            <li>Native German, fluent English and intermediate Spanish skills</li>
            </ul><br />
            <b>I'm passioned about</b>:<br /><br />

            UX Design and learning Front-End Development with HTML, CSS, JavaScript, React & Tailwind CSS.</p>
          </div>
        </div>
      </div>
    );
  };

export default Experience;