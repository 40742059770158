import React from 'react';
import {useEffect, useState} from 'react';

function BackTopButton() {
    const [BackTopButton, setBackTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 500) {
                setBackTopButton(true);
            } else {
                setBackTopButton(false);
            }
        })
    }, [])

    const scrollUp = () => {
        window.scrollTo({
                    top: 0,
                    behavior:'smooth'
                })
            }

    return <div className='App'>

        {BackTopButton && ( 
            <button style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                zIndex: '1000',
                backgroundColor: 'rgba(0,0,0,0.5)',
                color: 'white',
                padding: '10px',
                borderRadius: '5px',
                cursor: 'pointer',
                
            }}
            onClick={scrollUp}>Top</button>
        )}
    
    </div>        

  }
  
  export default BackTopButton;