import React from 'react';
import user1 from '../images/logos/user1.webp'
import user2 from '../images/logos/user2.webp'
import user3 from '../images/logos/user3.webp'

const Cards = () => {
  return (
    <div className='w-full py-20 px-4 bg-[#57757E]'>
        <h2 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2 mb-14 text-white text-center'>
              TESTIMONIALS
            </h2>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
          <div className='w-full shadow-xl bg-gray-80 flex flex-col p-4 my-4 rounded-lg bg-white hover:scale-105 duration-500'>
              <img className='w-20 mx-auto mt-[-3rem] rounded-md' src={user1} alt="/" />
              <div className='text-center font-medium'>
              <h2 className='text-2xl font-bold text-center py-2'>Lee R.</h2>
              <p className='py-2 border-b mx-8'>Atlanta, USA</p>
                  <p className='md:text-xl text-xl font-light py-2 mx-8 mt-2'>"Oliver is an excellent strategic marketing professional utilizing an effective process-driven, results-oriented approach to achieving favorable outcomes and driving progress."</p>
              </div>
          </div>
          <div className='w-full shadow-xl bg-gray-80 flex flex-col p-4 my-2 rounded-lg bg-white hover:scale-105 duration-500'>
              <img className='w-20 mx-auto mt-[-3rem] rounded-md' src={user2} alt="/" />
              <div className='text-center font-medium'>
              <h2 className='text-2xl font-bold text-center py-2'>Gavin S.</h2>
              <p className='py-2 border-b mx-8'>London, UK</p>
                  <p className='md:text-xl text-xl font-light py-2 mx-8 mt-2'>"Oliver is a great digital marketer with clear strategic vision and a focus on results and practical solutions that really deliver."</p>
              </div>
          </div>
          <div className='w-full shadow-xl bg-gray-80 flex flex-col p-4 my-4 rounded-lg bg-white hover:scale-105 duration-500'>
              <img className='w-20 mx-auto mt-[-3rem] rounded-md' src={user3} alt="/" />
              <div className='text-center font-medium'>
              <h2 className='text-2xl font-bold text-center py-2'>Mahrez T.</h2>
              <p className='py-2 border-b mx-8'>Montreal, CA</p>
                  <p className='md:text-xl text-xl font-light py-2 mx-8 mt-2'>"Oliver's personality, technical skills, out of the box thinking and understanding of customer needs make him an excellent digital strategy and solutions designer."</p>
              </div>
          </div>
      </div>
    </div>
  );
};

export default Cards;