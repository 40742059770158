import React from 'react';
import {
  FaLinkedin,
  FaGithubSquare,
  FaCodepen
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div id='Footer' className='mx-auto w-full py-16 px-8 grid lg:grid-cols-3 gap-8 text-gray-300 bg-[#1D2634]'>
      <div>
        <h1 className='w-full text-2xl font-bold'>Oliver Heesch</h1>
        <p className='py-4 md:text-xl text-[#57757E] text-xl font-light'>Business Strategist | Marketing Consultant | Change Agent | Data Driven Growth Hacker | Front-End Development enthusiast from Barcelona!</p>
        <div className='flex justify-between w-[50%] my-6'>
          <a href='https://www.linkedin.com/in/oliverheesch/' target='_blank' rel='noreferrer' className='hover:text-[#57757E] duration-300'><FaLinkedin size={30} /></a>
          <a href='https://github.com/ReviloBCN' target='_blank' rel='noreferrer' className='hover:text-[#57757E] duration-300'><FaGithubSquare size={30} /></a>
          <a href='https://codepen.io/ReviloBCN' target='_blank' rel='noreferrer' className='hover:text-[#57757E] duration-300'><FaCodepen size={30} /></a>
        </div>
        <h1 className='py-4 text-light text-[#DA031E]'>© Oliver Heesch | Barcelona 2023</h1>
      </div>
    </div>
  );
};

export default Footer;