import React from 'react';

const Contact = () => {
  return (
    <div id='Contact' className='w-full py-16 bg-[#1D2634] text-white px-4'>
      <div className='max-w-[1240px] mx-auto grid lg:grid-cols-3'>
        <div className='lg:col-span-2 my-4'>
          <h2 className='md:text-4xl sm:text-3xl text-2xl text-center font-bold py-2'>
            Tips, insights or just grow the network?
          </h2>
          <p className='md:text-xl text-xl text-center font-light'>Let's connect and start a conversation.</p>
        </div>
        <div className='my-4'>
          <div className='flex flex-col sm:flex-row items-center justify-between w-full'>
          
            <button className='hover:scale-105 duration-500 bg-[#DA031E] text-white rounded-md font-medium w-[150px] ml-4 my-6 px-4 py-3'><a href='https://www.linkedin.com/in/oliverheesch/' target='_blank' rel='noreferrer'>Connect</a></button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;