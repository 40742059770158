import React from 'react';

const Business = () => {
    return (
      <div id='Business' className='w-full bg-[#1D2634] py-16 px-4'>
        <div className='max-w-[1240px] mx-auto grid lg:grid-cols-2'>
          <div className='lg:col-span-2 my-4'>
            <h2 className='md:text-4xl sm:text-3xl text-3xl font-bold py-2 mb-4 text-white text-center'>
              MY SERVICE
            </h2>
            <p className='md:text-xl text-xl font-light text-white'>Since 2004 I offer strategic advisory, marketing leadership as well as development and execution of Product Marketing, Growth Marketing and Brand Strategies as a service for SMB’s in Germany, Austria, Switzerland, France, Belgium and Japan. Additionally I developed and deployed Graphic and Web Design projects for HORECA, Retail, Real Estate and Travel.<br /><br />
            Up to your business objective MaaS (Marketing-as-a-Service) can provide tailored strategies to accelerate your business by optimising your go-to-market sales and marketing activations, building your customer acquisition growth engine and help to gain efficiencies in management and stakeholder alignment while ensuring quality and on-time delivery.<br /><br />
            
            Want to know more? Let's have a conversation in a discovery call!<br /><br /></p>
            <button className='hover:scale-105 duration-500 bg-[#DA031E] text-white rounded-md font-medium w-[150px] ml-2 my-6 px-4 py-3'><a href='https://www.linkedin.com/in/oliverheesch/' target='_blank' rel='noreferrer'>Get In Touch</a></button>
          </div>
        </div>
      </div>
    );
  };

export default Business;