import React from 'react';
import Kimberly from '../images/logos/KimberlyClark.webp';
import WypAll from '../images/logos/WypAll.webp';
import Kimtech from '../images/logos/Kimtech.webp';
import Barry from '../images/logos/BarryCallebaut.webp';
import Callebaut from '../images/logos/Callebaut.webp';
import Academy from '../images/logos/ChocolateAcademy.webp';
import HP from '../images/logos/HP.webp';
import Webhelp from '../images/logos/Webhelp.webp';
import Facebook from '../images/logos/Facebook.webp';
import Klinik from '../images/logos/Elblandkliniken.webp';
import Cisco from '../images/logos/CiscoSystems.webp';
import Sodexho from '../images/logos/Sodexho.webp';

const Work = () => {
    return (
        <div id='Work' name='skills' className='w-full py-12 bg-[#57757E] text-white'>
          {/* Container */}
          <div className='max-w-[1240px] mx-auto p-2 flex flex-col justify-center w-full h-full'>
              <div>
                  <p className='text-4xl font-bold text-center'>BRANDS I WORKED FOR</p>
                  
              </div>
    
              <div className='w-full grid grid-cols-3 sm:grid-cols-6 gap-4 text-center py-12'>
                  <div className='shadow-md shadow-[#1D2634] hover:scale-105 duration-500'>
                      <img className='mx-auto rounded-lg' src={Kimberly} alt="" />
                      
                  </div>
                  <div className='shadow-md shadow-[#1D2634] hover:scale-105 duration-500'>
                      <img className='mx-auto rounded-lg' src={WypAll} alt="" />
                     
                  </div>
                  <div className='shadow-md shadow-[#1D2634] hover:scale-105 duration-500'>
                      <img className='mx-auto rounded-lg' src={Kimtech} alt="" />
                      
                  </div>
                  <div className='shadow-md shadow-[#1D2634] hover:scale-105 duration-500'>
                      <img className='mx-auto rounded-lg' src={Barry} alt="" />
                      
                  </div>
                  <div className='shadow-md shadow-[#1D2634] hover:scale-105 duration-500'>
                      <img className='mx-auto rounded-lg' src={Callebaut} alt="" />
                      
                  </div>
                  <div className='shadow-md shadow-[#1D2634] hover:scale-105 duration-500'>
                      <img className='mx-auto rounded-lg' src={Academy} alt="" />
                      
                  </div>
                  <div className='shadow-md shadow-[#1D2634] hover:scale-105 duration-500'>
                      <img className='mx-auto rounded-lg' src={HP} alt="" />
                      
                  </div>
                  <div className='shadow-md shadow-[#1D2634] hover:scale-105 duration-500'>
                      <img className='mx-auto rounded-lg' src={Webhelp} alt="" />
                      
                  </div>
                  <div className='shadow-md shadow-[#040c16] hover:scale-105 duration-500'>
                      <img className='mx-auto rounded-lg' src={Facebook} alt="" />
                      
                  </div>
                  <div className='shadow-md shadow-[#040c16] hover:scale-105 duration-500'>
                      <img className='mx-auto rounded-lg' src={Klinik} alt="" />
                      
                  </div>
                  <div className='shadow-md shadow-[#040c16] hover:scale-105 duration-500'>
                      <img className='mx-auto rounded-lg' src={Cisco} alt="" />
                      
                  </div>
                  <div className='shadow-md shadow-[#1D2634] hover:scale-105 duration-500'>
                      <img className='mx-auto rounded-lg' src={Sodexho} alt="" />
                      
                  </div>
              </div>
          </div>
        </div>
      );
    };

export default Work;