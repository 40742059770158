import React from 'react';
import { data } from "../data/data.js";


const Portfolio = () => {

    const project = data;

    return (
        <div name='protfolio' className='w-full text-[#1D2634] bg-white py-8'>
            <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full py-12'>
                <div className='pb-8'>
                    <p className='md:text-4xl sm:text-3xl text-3xl font-bold text-[#1D2634] text-center py-6'>
                        CERTIFICATIONS
                    </p>
                </div>

                <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4 p-4'>

                    {project.map((item, index) => (
                        <div
                            key={index}
                            style={{ backgroundImage: `url(${item.image})` }}
                            className='shadow-lg shadow-[#57757E] group container rounded-md flex justify-center text-center items-center mx-auto content-div'>
                            <div className='opacity-0 group-hover:opacity-60'><span className='md:text-xl text-xl font-light text-white tracking-wider p-2'>{item.name}</span>
                                {/*<div className='pt-8 text-center'>
                                <a href={item.url} target='_blank' rel='noreferrer'>
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-[#1D2634] text-white font-regular text-lg'>Learn More</button></a>
                                </div>*/}
                            </div>
                        </div>
                    ))}

                </div>

            </div>
        </div>
    );
};

export default Portfolio;
