import React from 'react';
import Oliver from '../images/oliver_heesch_consulting.webp';

const About = () => {
    return (
      <div id='About' className='w-full bg-white py-16 px-4'>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
          <img className='w-[400px] mx-auto my-6' src={Oliver} alt='Oliver Heesch Marketing Consulting' />
          <div className='flex flex-col justify-center'>
            <h2 className='md:text-4xl sm:text-3xl text-3xl text-[#1D2634] font-bold py-4'>THATS ME</h2>
            <p className='md:text-xl text-xl font-light'>
            I'm a senior marketing leader with extensive international experience delivering solutions and leading transformation in top tier multinational companies and SMB’s in EMEA, APAC, US & LATAM. Additionally I collaborated for the book <a href="https://books.google.es/books?id=X--VDwAAQBAJ&printsec=frontcover&dq=exponential+transformations&hl=es&sa=X&redir_esc=y#v=onepage&q=exponential%20transformations&f=false" target="_blank" rel="noreferrer"><b>Exponential Transformation: Evolve Your Organisation </b></a> 
            while working on my ExO certification with <a href="https://openexo.com/community/oheesch" target="_blank" rel="noreferrer"><b>Open ExO.</b></a><br /><br />
            Used to work in multicultural and fast paced environments leading Marketing Strategy, Go-to-Market, Brand Communication Strategies, Demand Generation, Marketing Automation, Content Strategy, eCommerce and efficient Sales Enablement. I connect marketing to business results, drive growth and deliver incremental value by gaining a competitive advantage through data.<br /><br />
            I'm a tech enthusiast, a creative, strategic thinker and change driver with strong people skills.
            </p>
          </div>
        </div>
      </div>
    );
  };

export default About;