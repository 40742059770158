import React from 'react'
import Navbar from './components/Navbar';
import Intro from './components/Intro';
import About from './components/About';
import Work from './components/Work';
import Business from './components/Business';
import Experience from './components/Experience';
import Cards from './components/Cards';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Accordion from './components/Accordion';
import Footer from './components/Footer';
import BackTop from './components/BackTop';



function App() {
  return (
    <div>
      <Navbar />
      <Intro />
      <About />
      <Work />
      <Business />
      <Experience />
      <Cards />
      <Contact />
      <Portfolio />
      <Accordion />
      <Footer />
      <BackTop />
    </div>
  );
}

export default App;
