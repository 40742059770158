import React, { useState } from 'react';
import {AiOutlineCaretDown, AiOutlineCaretUp} from 'react-icons/ai';

const dataCollection = [
    {
      question: 'Why Data Driven Marketing?',
      answer: 'Data-driven marketing is a concept of optimising customer facing communication along the customer journey, with existing customer data to predict future behaviour. These insights enable personalised strategies with higher impact.'
    },{
      question: 'Why Marketing Automation?',
      answer: 'Marketing automation allows you to guide audiences through a personalised customer journeys, track across channels and enables attribution and effective scoring. Ultimately it can improve lead quality and conversion rates.'
    },{
      question: 'What Is Demand Generation?',
      answer: 'While lead Generation has a short-term sales focus, Demand Generation focuses on reaching and educating customers for the long run to keep the service or product top of mind to be considered when a customer need arises.'
    },{
      question: 'What Is Customer Experience?',
      answer: 'Customer Experience is how the customer perceives a brand through all aspects along the customer journey. A positive customer experience can build loyalty, retain customers and encourage lasting brand advocacy.'
    },{
      question: 'What is Marketing-as-a-Service?',
      answer: 'MaaS or Marketing-as-a-Service offers tailored solution to support your business by delivering agile, on-demand, value-based marketing solutions from strategy to execution.'
    }, {
      question: 'Why Work With Oliver Heesch?',
      answer: 'I am always keen to contribute and leading cross-functional teams to the next level to help organisations transforming to real B2H businesses winning in customer intimacy and gain a competitive advantage through insights.'
    }
    
  ];

  function Accordion() {
    const [accordion, setActiveAccordion] = useState(-1);
  
    function toggleAccordion(index) {
      if (index === accordion) {
        setActiveAccordion(-1);
        return
      }
      setActiveAccordion(index);
    };
  
    return (
      <>
        <div className='container__faq w-full py-4 px-6 bg-[#57757E]'>
          <div>
            <h2 className='accordion__title md:text-4xl sm:text-3xl text-2xl font-bold py-6 text-white text-center'>GOOD TO KNOW</h2>
          </div>
          <div className='accordion__faq max-w-[1240px] mx-auto grid lg:grid-cols-3 py-4'>
            { dataCollection.map((item, index) =>
                <div key={index} onClick={() => toggleAccordion(index)}>
                  <div className='accordion__faq-heading md:text-xl sm:text-lg text-lg text-white rounded-md font-regular pl-4 py-2 bg-[#1D2634] hover:scale-105 duration-500'>
                    <h3 className={accordion === index ? "active" : ""}>{item.question}</h3>
                    <div>
                      {accordion === index ?
                        <span className="verticle"><AiOutlineCaretUp className='mr-4 rounded-sm text-white bg-[#DA031E]' /></span> : <span className="horizental"><AiOutlineCaretDown className='mr-4' /></span>}
                    </div>
                  </div>
                  <div className='md:text-xl text-xl font-light px-2 py-2 text-white'><p className={accordion === index ? "active" : "inactive"} >{item.answer}</p></div>
                </div>
              )
            }
          </div>
        </div>
      </>
    );
  }

export default Accordion;