import Google from '../images/logos/Google.webp'
import Meta from '../images/logos/Meta.webp'
import AI from '../images/logos/AI.webp'
import Sigma from '../images/logos/Sigma.webp'
import Exo from '../images/logos/Exo.webp'
import NLP from '../images/logos/NLP.webp'

export const data=[
    {
        id:1,
        name:'Inbound Marketing Certification by HUBSPOT',
        image:NLP,
        url:'',
    },
    {
        id:2,
        name:'UX Design Foundation Certification by GOOGLE',
        image:Google,
        url:'',
    },
    {
        id:3,
        name:'Front-End Development Certification by META',
        image:Meta,
        url:'',
    },
    {
        id:4,
        name:'Generative AI Foundations by GOOGLE',
        image:AI,
        url:'',
    },
    {
        id:5,
        name:'Six Sigma Green Belt Certification by IASSC',
        image:Sigma,
        url:'',
    },
    {
        id:6,
        name:'ExO Foundations by OPENEXO',
        image:Exo,
        url:'',
    },
 
]